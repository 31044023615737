var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _vm.productDetail.id
        ? _c(
            "b-card",
            [
              _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c("b-col", { attrs: { lg: "12" } }, [
                    _c("div", { staticClass: "blockquote" }, [
                      _c("h4", [_vm._v("Omni Public Info")])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "productDetailRow",
                  staticStyle: { "margin-left": "40px" }
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "productDetailBorder" }, [
                      _c("img", {
                        attrs: { src: _vm.productDetail.deviceImage }
                      })
                    ]),
                    _c(
                      "div",
                      { staticClass: "productDetailImageRow" },
                      _vm._l(_vm.productDetail.productPicture, function(
                        item,
                        key
                      ) {
                        return _c("img", {
                          key: key,
                          attrs: { src: item.value }
                        })
                      }),
                      0
                    )
                  ]),
                  _c("div", { staticClass: "productDetailContent" }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          color: "#3eb1c8",
                          "font-weight": "700"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productDetail.productSeriesName) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "13px",
                          color: "#aaaaaa",
                          "font-family": "'Arial Normal', 'Arial', sans-serif"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.productDetail.remarks) + " ")]
                    ),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Product Model #:")]
                      ),
                      _vm._v(" " + _vm._s(_vm.productDetail.modelName) + " ")
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Variant Code #:")]
                      ),
                      _vm._v(" " + _vm._s(_vm.productDetail.variantCode) + " ")
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Marketing Name:")]
                      ),
                      _vm._v(" " + _vm._s(_vm.productDetail.deviceName) + " ")
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Business Type:")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.productDetail.oilType == 2
                              ? "CBD/THC Concentrates"
                              : "Nicotine"
                          ) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Product Type:")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.productDetail.type == 3
                              ? "Disposable"
                              : "Batteries"
                          ) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Available Compatibility:")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.productDetail.availableCompatibilityId == 1
                              ? "OMNI NFC"
                              : "OMNI Hub"
                          ) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Available Tank Size:")]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.productDetail.taskCapacityValue) + " "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.productDetail.brandProduct
        ? _c(
            "b-card",
            [
              _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c("b-col", { attrs: { lg: "12" } }, [
                    _c("div", { staticClass: "blockquote" }, [
                      _c("h4", [_vm._v("Client Business Info")])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vo-column", staticStyle: { width: "100%" } },
                [
                  _c("div", { staticClass: "productDetailRow" }, [
                    _c(
                      "div",
                      { staticClass: "productDetailColumn" },
                      [
                        _c("div", { staticClass: "vo-row" }, [
                          _c("p", { staticClass: "productInfo" }, [
                            _c("strong", [_vm._v("Brand Name:")])
                          ]),
                          _c("p", { staticClass: "productInfoContent" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.productDetail.brandProduct.brandName
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "vo-row" }, [
                          _c("p", { staticClass: "productInfo" }, [
                            _c("strong", [_vm._v("Brand Product Model#:")])
                          ]),
                          _c("p", { staticClass: "productInfoContent" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.productDetail.brandProduct.modelName
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "vo-row" }, [
                          _c("p", { staticClass: "productInfo" }, [
                            _c("strong", [_vm._v("Brand Marketing Name:")])
                          ]),
                          _c("p", { staticClass: "productInfoContent" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.productDetail.brandProduct.deviceName
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { cols: "8" } }, [
                              _c("div", { staticClass: "vo-row" }, [
                                _c("p", { staticClass: "productInfo" }, [
                                  _c("strong", [
                                    _vm._v("Brand Product Introduction:")
                                  ])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "productInfoContent" },
                                  [
                                    _c(
                                      "b-row",
                                      {
                                        staticStyle: { "margin-left": "-10px" }
                                      },
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c("b-form-textarea", {
                                              attrs: {
                                                id: "company-introduction",
                                                placeholder:
                                                  _vm.productDetail.brandProduct
                                                    .remarks,
                                                disabled: true,
                                                rows: "10",
                                                "max-rows": "10",
                                                maxlength: "2000"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            ])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "vo-row" }, [
                          _c("p", { staticClass: "productInfo" }, [
                            _c("strong", [_vm._v("Brand Product Status:")])
                          ]),
                          _c("p", { staticClass: "productInfoContent" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.productDetail.brandProduct.status == 0
                                    ? "Eanable"
                                    : "Disable"
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { cols: "8" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "vo-row",
                                  staticStyle: { "padding-top": "10px" }
                                },
                                [
                                  _c("p", { staticClass: "productInfo" }, [
                                    _c("strong", [
                                      _vm._v("Brand Flavor Pictures:")
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "productInfoContent" },
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticStyle: {
                                            "margin-left": "-10px"
                                          },
                                          attrs: { "cols-xl": "4" }
                                        },
                                        _vm._l(
                                          _vm.productDetail.brandProduct
                                            .flavorList,
                                          function(item, i) {
                                            return _c(
                                              "b-col",
                                              {
                                                key: i,
                                                staticClass: "pd cursor",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openImage(
                                                      item.flavorPicture
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("ImageComponets", {
                                                  attrs: {
                                                    image: item.flavorPicture
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "overlay",
          attrs: { id: "overlay" },
          on: {
            click: function($event) {
              return _vm.closeImage()
            }
          }
        },
        [
          _c("img", {
            staticClass: "overlay-content",
            attrs: { id: "overlayImage" }
          })
        ]
      ),
      _c(
        "b-form-group",
        { attrs: { "label-cols-lg": "2" } },
        [
          _c(
            "b-button",
            {
              staticClass: "custom-width",
              attrs: { type: "reset" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v("Return ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }