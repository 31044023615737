<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-card v-if="productDetail.id">
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Omni Public Info</h4>
          </div>
        </b-col>
      </b-row>
      <div class="productDetailRow" style="margin-left: 40px;">
        <!-- 左边 -->
        <div>
          <div class="productDetailBorder">
            <img :src="productDetail.deviceImage" />
          </div>
          <div class="productDetailImageRow">
            <img
              :src="item.value"
              v-for="(item, key) in productDetail.productPicture"
              :key="key"
            />
          </div>
        </div>
        <!-- 右边 -->
        <div class="productDetailContent">
          <p style="font-size: 18px; color: #3eb1c8; font-weight: 700">
            {{ productDetail.productSeriesName }}
          </p>
          <p
            style="
              font-size: 13px;
              color: #aaaaaa;
              font-family: 'Arial Normal', 'Arial', sans-serif;
            "
          >
            {{ productDetail.remarks }}
          </p>
          <p>
            <strong class="productDetailContentColor">Product Model #:</strong>
            {{ productDetail.modelName }}
          </p>
          <p>
            <strong class="productDetailContentColor">Variant Code #:</strong>
            {{ productDetail.variantCode }}
          </p>
          <p>
            <strong class="productDetailContentColor">Marketing Name:</strong>
            {{ productDetail.deviceName }}
          </p>
          <p>
            <strong class="productDetailContentColor">Business Type:</strong>
            {{
              productDetail.oilType == 2 ? "CBD/THC Concentrates" : "Nicotine"
            }}
          </p>
          <p>
            <strong class="productDetailContentColor">Product Type:</strong>
            {{ productDetail.type == 3 ? "Disposable" : "Batteries" }}
          </p>
          <p>
            <strong class="productDetailContentColor"
              >Available Compatibility:</strong
            >
            {{
              productDetail.availableCompatibilityId == 1
                ? "OMNI NFC"
                : "OMNI Hub"
            }}
          </p>
          <p>
            <strong class="productDetailContentColor"
              >Available Tank Size:</strong
            >
            {{ productDetail.taskCapacityValue }}
          </p>
        </div>
      </div>
    </b-card>

    <b-card v-if="productDetail.brandProduct">
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Client Business Info</h4>
          </div>
        </b-col>
      </b-row>
      <div class="vo-column" style="width: 100%">
        <div class="productDetailRow">
          <div class="productDetailColumn">
            <div class="vo-row">
              <p class="productInfo">
                <strong>Brand Name:</strong>
              </p>
              <p class="productInfoContent">
                {{ productDetail.brandProduct.brandName }}
              </p>
            </div>

            <div class="vo-row">
              <p class="productInfo">
                <strong>Brand Product Model#:</strong>
              </p>
              <p class="productInfoContent">
                {{ productDetail.brandProduct.modelName }}
              </p>
            </div>

            <div class="vo-row">
              <p class="productInfo">
                <strong>Brand Marketing Name:</strong>
              </p>
              <p class="productInfoContent">
                {{ productDetail.brandProduct.deviceName }}
              </p>
            </div>

            <b-row>
              <b-col cols="8">
                <div class="vo-row">
                  <p class="productInfo">
                    <strong>Brand Product Introduction:</strong>
                  </p>

                  <div class="productInfoContent">
                    <b-row style="margin-left: -10px">
                      <b-col>
                        <b-form-textarea
                          id="company-introduction"
                          :placeholder="productDetail.brandProduct.remarks"
                          :disabled="true"
                          rows="10"
                          max-rows="10"
                          maxlength="2000"
                        ></b-form-textarea>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>

            <div class="vo-row">
              <p class="productInfo">
                <strong>Brand Product Status:</strong>
              </p>
              <p class="productInfoContent">
                {{
                  productDetail.brandProduct.status == 0 ? "Eanable" : "Disable"
                }}
              </p>
            </div>

            <!-- <div class="productDetailRow">
              <div class="productDetailImageRow">
                <div
                  v-for="(item, i) in productDetail.brandProduct.flavorList"
                  :key="i" style="width: 200px;"
                >
                  <ImageComponets :image="item.flavorPicture" />
                </div>
              </div>
            </div> -->

            <b-row>
              <b-col cols="8">
                <div class="vo-row" style="padding-top: 10px">
                  <p class="productInfo">
                    <strong>Brand Flavor Pictures:</strong>
                  </p>
                  <div class="productInfoContent">
                    <b-row cols-xl="4" style="margin-left: -10px">
                      <b-col
                        v-for="(item, i) in productDetail.brandProduct
                          .flavorList"
                        :key="i"
                        class="pd cursor"
                        @click="openImage(item.flavorPicture)"
                      >
                        <ImageComponets :image="item.flavorPicture" />
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>

    <div id="overlay" class="overlay" @click="closeImage()">
      <img id="overlayImage" class="overlay-content" />
    </div>

    <b-form-group label-cols-lg="2">
      <b-button type="reset" class="custom-width" @click="$router.back()"
        >Return
      </b-button>
    </b-form-group>
  </Layout>
</template>
<style scoped>
.productDetailBorder {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.productDetailRow {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
.productDetailImageRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  height: 125px;
}

.modal-header {
  display: none;
}
.modal-footer {
  display: none;
}
.productDetailContent {
  margin-left: 40px;
  width: 400px;
}
.productDetailContentColor {
  color: #333333;
}
.productDetailColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.productInfo {
  width: 200px;
  text-align: right;
  color: #333333;
}

.productInfoContent {
  text-align: left;
  flex: 1;
  margin-left: 5px;
}
.pd {
  padding-bottom: 10px;
}

.overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  text-align: center;
  justify-content: center;
  align-items: center;
}

</style>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img, upload_multiple_file } from "@/api/upload";
import goback from "@/components/goback";
import FormRow from "@/components/FormRow.vue";
import ImageComponets from "@/components/brand/ImageComponets.vue";

/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    goback,
    FormRow,
    ImageComponets,
  },
  data() {
    return {
      page: {
        title: "Brand Product Details",
        items: [
          {
            text: this.$store.getters.getTitle,
          },
          {
            text: "Client&Brand",
          },
          {
            text: "Brand Product Mgmt",
          },
          {
            text: "Brand Product Details",
            active: true,
          },
        ],
      },
      productDetail: {},
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    onReady: onReady,
    init() {
      if (this.$route.query.id) {
        this.selectBrandProductById();
      }
    },
    selectBrandProductById() {
      this.$api.CartridgeModel.selectBrandProductById({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.success) {
          this.productDetail = res.data;
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },

    openImage(src) {
      document.getElementById("overlay").style.display = "flex";
      document.getElementById("overlayImage").src = src;
    },
    closeImage() {
      document.getElementById("overlay").style.display = "none";
    },
  },
};
</script>
